import { v4 as uuid } from 'uuid';

import { GENDER, AGE_RANGE, ETHNICITY, HAIR_COLOUR, AppData, Domain, LabelTitles, Character, LabelKeys, CONNECTION_TYPE } from './types';
import { CURRENT_SAVE_FORMAT_VERSION } from './values';

export const getEmptySaveFormat = (): AppData => ({
  version: CURRENT_SAVE_FORMAT_VERSION,
  domains: [getEmptyDomain(uuid())],
});

export const getEmptyDomain = (id = uuid()): Domain => ({
  id,
  title: '',
  subtitle: '',
  labels: getEmptyLabelTitles(),
  characters: [],
});
export const getEmptyLabelTitles = (): LabelTitles => ({
  green: '',
  yellow: '',
  orange: '',
  red: '',
  purple: '',
  blue: '',
});
export const getEmptyCharacter = (id = uuid()): Character => ({
  id,
  fullName: '',
  desc: '',
  labels: [],
  gender: GENDER.UNKNOWN,
  age: AGE_RANGE.UNKNOWN,
  ethnicity: ETHNICITY.WHITE,
  hairColour: HAIR_COLOUR.BRUNETTE,
  connections: [],
  notes: '',
});

export const getDefaultState = (): AppData => ({
  version: 2,
  domains: [
    {
      id: 'bd885792-ecc6-4335-bbd9-38816189abef',
      title: 'Harry Potter',
      subtitle: 'by JK Rowling',
      labels: {
        //
        [LabelKeys.GREEN]: 'Main',
        [LabelKeys.YELLOW]: 'Friends',
        [LabelKeys.ORANGE]: 'Enemies',
        [LabelKeys.RED]: 'Students',
        [LabelKeys.PURPLE]: 'Teachers',
        [LabelKeys.BLUE]: '',
      },
      characters: [
        {
          id: 'harry-id',
          fullName: 'Harry Potter',
          desc: 'The Boy Who Lived, marked by lightning scar, prophesied to defeat Voldemort',
          labels: [LabelKeys.GREEN, LabelKeys.RED],
          gender: GENDER.MALE,
          age: AGE_RANGE.TEENAGER,
          ethnicity: ETHNICITY.WHITE,
          hairColour: HAIR_COLOUR.BLACK,
          connections: [
            { type: CONNECTION_TYPE.FRIEND_OF, from: 'harry-id', to: 'hermione-id' },
            { type: CONNECTION_TYPE.FRIEND_OF, from: 'harry-id', to: 'ron-id' },
            { type: CONNECTION_TYPE.ENEMY_OF, from: 'harry-id', to: 'draco-id' },
            { type: CONNECTION_TYPE.ENEMY_OF, from: 'harry-id', to: 'snape-id' },
          ],
          notes: 'Gryffindor student, exceptional at Defense Against the Dark Arts',
        },
        {
          id: 'hermione-id',
          fullName: 'Hermione Granger',
          desc: 'Brilliant witch, brightest witch of her age',
          labels: [LabelKeys.GREEN, LabelKeys.RED],
          gender: GENDER.FEMALE,
          age: AGE_RANGE.TEENAGER,
          ethnicity: ETHNICITY.WHITE,
          hairColour: HAIR_COLOUR.BRUNETTE,
          connections: [
            { type: CONNECTION_TYPE.FRIEND_OF, from: 'hermione-id', to: 'harry-id' },
            { type: CONNECTION_TYPE.FRIEND_OF, from: 'hermione-id', to: 'ron-id' },
            { type: CONNECTION_TYPE.ENEMY_OF, from: 'hermione-id', to: 'draco-id' },
          ],
          notes: 'Gryffindor student, exceptionally intelligent and skilled in magic',
        },
        {
          id: 'ron-id',
          fullName: 'Ron Weasley',
          desc: 'Loyal friend, youngest Weasley son',
          labels: [LabelKeys.GREEN, LabelKeys.RED],
          gender: GENDER.MALE,
          age: AGE_RANGE.TEENAGER,
          ethnicity: ETHNICITY.WHITE,
          hairColour: HAIR_COLOUR.AUBURN,
          connections: [
            { type: CONNECTION_TYPE.FRIEND_OF, from: 'ron-id', to: 'harry-id' },
            { type: CONNECTION_TYPE.SIBLING, from: 'ron-id', to: 'ginny-id' },
            { type: CONNECTION_TYPE.ENEMY_OF, from: 'ron-id', to: 'draco-id' },
          ],
          notes: 'Gryffindor student, skilled chess player',
        },
        {
          id: 'dumbledore-id',
          fullName: 'Albus Dumbledore',
          desc: 'Headmaster of Hogwarts, powerful wizard',
          labels: [LabelKeys.PURPLE],
          gender: GENDER.MALE,
          age: AGE_RANGE.ELDERLY,
          ethnicity: ETHNICITY.WHITE,
          hairColour: HAIR_COLOUR.GRAY,
          connections: [
            { type: CONNECTION_TYPE.BOSS_OF, from: 'dumbledore-id', to: 'mcgonagall-id' },
            { type: CONNECTION_TYPE.BOSS_OF, from: 'dumbledore-id', to: 'snape-id' },
          ],
          notes: 'Leader of the Order of the Phoenix',
        },
        {
          id: 'voldemort-id',
          fullName: 'Tom Riddle',
          desc: 'Dark Lord, main antagonist',
          labels: [LabelKeys.ORANGE],
          gender: GENDER.MALE,
          age: AGE_RANGE.MIDDLE_AGED,
          ethnicity: ETHNICITY.WHITE,
          hairColour: HAIR_COLOUR.BLACK,
          connections: [{ type: CONNECTION_TYPE.ENEMY_OF, from: 'voldemort-id', to: 'harry-id' }],
          notes: 'Former Hogwarts student turned dark wizard',
        },
        {
          id: 'mcgonagall-id',
          fullName: 'Minerva McGonagall',
          desc: 'Transfiguration professor, Head of Gryffindor',
          labels: [LabelKeys.PURPLE],
          gender: GENDER.FEMALE,
          age: AGE_RANGE.ELDERLY,
          ethnicity: ETHNICITY.WHITE,
          hairColour: HAIR_COLOUR.BLACK,
          connections: [{ type: CONNECTION_TYPE.EMPLOYEE_OF, from: 'mcgonagall-id', to: 'dumbledore-id' }],
          notes: 'Strict but fair teacher, skilled animagus',
        },
        {
          id: 'ginny-id',
          fullName: 'Ginny Weasley',
          desc: 'Youngest Weasley, skilled witch',
          labels: [LabelKeys.YELLOW, LabelKeys.RED],
          gender: GENDER.FEMALE,
          age: AGE_RANGE.TEENAGER,
          ethnicity: ETHNICITY.WHITE,
          hairColour: HAIR_COLOUR.AUBURN,
          connections: [
            { type: CONNECTION_TYPE.SIBLING, from: 'ginny-id', to: 'ron-id' },
            { type: CONNECTION_TYPE.IN_LOVE_WITH, from: 'ginny-id', to: 'harry-id' },
          ],
          notes: 'Gryffindor student, talented at hexes',
        },
        {
          id: 'snape-id',
          fullName: 'Severus Snape',
          desc: 'Potions Master, Head of Slytherin House, double agent',
          labels: [LabelKeys.PURPLE, LabelKeys.ORANGE],
          gender: GENDER.MALE,
          age: AGE_RANGE.MIDDLE_AGED,
          ethnicity: ETHNICITY.WHITE,
          hairColour: HAIR_COLOUR.BLACK,
          connections: [
            {
              type: CONNECTION_TYPE.EMPLOYEE_OF,
              from: 'snape-id',
              to: 'dumbledore-id',
            },
            {
              type: CONNECTION_TYPE.ENEMY_OF,
              from: 'snape-id',
              to: 'harry-id',
            },
            {
              type: CONNECTION_TYPE.BOSS_OF,
              from: 'snape-id',
              to: 'draco-id',
            },
          ],
          notes: "Complex character with deep connection to Harry's mother Lily. Skilled in Occlumency and potion-making.",
        },
        {
          id: 'draco-id',
          fullName: 'Draco Malfoy',
          desc: 'Slytherin student, from pure-blood wizarding family',
          labels: [LabelKeys.ORANGE, LabelKeys.RED],
          gender: GENDER.MALE,
          age: AGE_RANGE.TEENAGER,
          ethnicity: ETHNICITY.WHITE,
          hairColour: HAIR_COLOUR.BLONDE,
          connections: [
            {
              type: CONNECTION_TYPE.ENEMY_OF,
              from: 'draco-id',
              to: 'harry-id',
            },
            {
              type: CONNECTION_TYPE.ENEMY_OF,
              from: 'draco-id',
              to: 'hermione-id',
            },
            {
              type: CONNECTION_TYPE.ENEMY_OF,
              from: 'draco-id',
              to: 'ron-id',
            },
          ],
          notes: 'School rival to Harry Potter, later becomes reluctant Death Eater',
        },
      ],
    },
  ],
});
