import React, { useState } from 'react';
import { Typography, Grid, IconButton, Box } from '@material-ui/core';
import { useAppContext } from '../../data/AppContext';
import { Character } from '../../data/types';
import HeadItem from '../../components/HeadItem/HeadItem';
import { getScreenSize } from '../../utils/sizing';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import useStyles from './styles';
import SaveLoad from '../../components/SaveLoad/SaveLoad';

const getNumOfChars = () => {
  const perRow = {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 4,
    xl: 4,
  }[getScreenSize()];
  return perRow * 3;
};

const getRandomCharacters = (appData, numOfChars = getNumOfChars()): Character[] => {
  const allChar = appData.domains.flatMap(({ characters }) => characters);

  if (allChar.length <= numOfChars) {
    return allChar;
  }

  let out = [];
  for (let i = 0; i < numOfChars; i++) {
    let random;
    do {
      random = allChar[Math.floor(Math.random() * allChar.length)];
    } while (out.includes(random));
    out.push(random);
  }
  return out;
};

const HomePage = () => {
  const classes = useStyles();
  const { appData } = useAppContext();
  const [random, setRandom] = useState<Character[]>(getRandomCharacters(appData));

  const randomiseCharacters = () => setRandom(getRandomCharacters(appData));

  return (
    <div className={classes.home}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="subtitle1">Random Characters</Typography>
        </Box>
        <Box>
          <IconButton onClick={randomiseCharacters}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {random.map((char, index) => (
          <HeadItem key={char.id} character={char} />
        ))}
      </Grid>
      <SaveLoad />
    </div>
  );
};

export default HomePage;
